var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticClass:"text-h4 mb-2"},[_vm._v(_vm._s(_vm.$t('title')))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"teal","dark":"","to":"/contractors-in-works/add"}},[_vm._v(_vm._s(_vm.$t('btn.add')))])],1)],1),_c('v-row',{staticClass:"align-center mb-5"},[_vm._l((_vm.filter),function(item,index){return [_c('v-col',{key:("filter-item-" + index),attrs:{"cols":"4"}},[(item.type === 'datePicker')?[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":item.name,"prepend-icon":"mdi-calendar","readonly":"","clearable":"","color":"teal","dense":""},on:{"input":function($event){return _vm.changeFilter()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"teal","range":""},on:{"change":function($event){item.open = false; _vm.changeFilter()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)]:_vm._e(),(item.type === 'contractors')?[_c('SelectContractors',{attrs:{"label":item.name,"dense":""},on:{"input":function($event){return _vm.changeFilter()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:_vm._e(),(item.type === 'status')?[_c('SelectContractorsInWorksStatus',{attrs:{"label":item.name,"dense":""},on:{"input":function($event){return _vm.changeFilter()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]:_vm._e()],2)]})],2),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"footer-props":_vm.footer,"server-items-length":_vm.total,"page":_vm.page,"loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.UF_CONTRACTOR",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.find = _vm.contractors.find(function (o) { return o.ID === item.UF_CONTRACTOR; })) ? _vm.find.UF_NAME : '-')+" ")]}},{key:"item.UF_STATUS",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.find = _vm.statusList.find(function (o) { return o.id === item.UF_STATUS; })) ? _vm.find.name : '-')+" ")]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"teal"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(item.UF_STATUS === 11)?[_c('v-list-item',{attrs:{"to":("/contractors-in-works/" + (item.ID) + "/draft/")}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('btn.detail'))+" ")])],1)]:[_c('v-list-item',{attrs:{"to":("/contractors-in-works/" + (item.ID))}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('btn.detail'))+" ")])],1)]],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }