<i18n>
{
  "ru": {
    "title": "У подрядчиков"
  },
  "he": {
    "title": "נשלח לספקים"
  }
}
</i18n>

<template>
  <div>
    <v-row>
      <v-col
        cols="auto"
      >
        <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>
      </v-col>

      <v-spacer></v-spacer>

      <v-col
        cols="auto"
      >
        <v-btn
          color="teal"
          dark
          to="/contractors-in-works/add"
        >{{ $t('btn.add') }}</v-btn>
      </v-col>
    </v-row>

    <v-row class="align-center mb-5">
      <template v-for="(item, index) in filter">
        <v-col
          cols="4"
          :key="`filter-item-${index}`"
        >
          <template v-if="item.type === 'datePicker'">
            <v-menu
              v-model="item.open"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.value"
                  :label="item.name"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  color="teal"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  @input="changeFilter()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.value"
                no-title
                scrollable
                color="teal"
                range
                @change="item.open = false; changeFilter()"
              >
              </v-date-picker>
            </v-menu>
          </template>

          <template v-if="item.type === 'contractors'">
            <SelectContractors
              v-model="item.value"
              :label="item.name"
              dense
              @input="changeFilter()"
            ></SelectContractors>
          </template>

          <template v-if="item.type === 'status'">
            <SelectContractorsInWorksStatus
              v-model="item.value"
              :label="item.name"
              dense
              @input="changeFilter()"
            ></SelectContractorsInWorksStatus>
          </template>
        </v-col>
      </template>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      class="elevation-1"
      :footer-props="footer"
      :server-items-length="total"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:item.UF_CONTRACTOR="{ item }">
        {{ (find = contractors.find((o) => o.ID === item.UF_CONTRACTOR)) ? find.UF_NAME : '-' }}
      </template>

      <template v-slot:item.UF_STATUS="{ item }">
        {{ (find = statusList.find((o) => o.id === item.UF_STATUS)) ? find.name : '-' }}
      </template>

      <template v-slot:item.btn="{ item }">
        <v-menu
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              color="teal"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <template v-if="item.UF_STATUS === 11">
              <v-list-item
                :to="`/contractors-in-works/${item.ID}/draft/`"
              >
                <v-list-item-title>
                  {{ $t('btn.detail') }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-else>
              <v-list-item
                :to="`/contractors-in-works/${item.ID}`"
              >
                <v-list-item-title>
                  {{ $t('btn.detail') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SelectContractors from '@/components/SelectContractors.vue';
import SelectContractorsInWorksStatus from '@/components/SelectContractorsInWorksStatus.vue';

export default {
  name: 'ContractorsInWorksList',
  components: {
    SelectContractors,
    SelectContractorsInWorksStatus,
  },
  data: (vm) => ({
    items: [],
    filter: [
      {
        name: vm.$t('props.UF_DATE'),
        code: 'UF_DATE',
        value: null,
        type: 'datePicker',
        open: false,
      },
      {
        name: vm.$t('props.UF_CONTRACTOR'),
        code: 'UF_CONTRACTOR',
        value: null,
        type: 'contractors',
        list: [],
      },
      {
        name: vm.$t('props.UF_STATUS'),
        code: 'UF_STATUS',
        value: null,
        type: 'status',
        list: [],
      },
    ],
    headers: [
      {
        text: '№',
        value: 'ID',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_DATE'),
        value: 'UF_DATE',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_CONTRACTOR'),
        value: 'UF_CONTRACTOR',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_STATUS'),
        value: 'UF_STATUS',
        sortable: false,
      },
      {
        text: '',
        value: 'btn',
        align: 'right',
        sortable: false,
      },
    ],
    footer: {
      'disable-items-per-page': true,
      'items-per-page-options': [50, 100],
    },
    page: 1,
    limit: 50,
    total: 0,
    loading: false,
  }),
  watch: {
    page() {
      this.getContractorsInWorksList();
    },
  },
  computed: {
    contractors() {
      return this.$store.getters.getContractors;
    },
    statusList() {
      return this.$store.getters.getContractorsInWorksStatus;
    },
    paramsFilter() {
      const params = {};
      const filterProps = this.filter.filter((item) => item.value || item.value === false);

      filterProps.forEach((item) => {
        params[item.code] = item.value;
      });

      return params;
    },
  },
  methods: {
    changeFilter() {
      this.page = 1;
      this.getContractorsInWorksList();
    },
    getContractorsInWorksList() {
      this.loading = true;

      const params = {
        order: {
          UF_DATE: 'DESC',
        },
        filter: this.paramsFilter,
        select: ['*'],
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      };

      this.$store.dispatch('getContractorsInWorksList', { params }).then((response) => {
        this.items = response.data.data.result;
        this.total = response.data.data.count;

        this.loading = false;
      });
    },
  },
  created() {
    this.getContractorsInWorksList();
  },
};
</script>
